import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IssuerActions, SchemeActions, SnackbarActions } from 'actionsets'
import { Pagination, ErrorBanner, SearchCombo, Select } from 'components'
import Dependent from 'containers/shared/Dependent'
import IconButton from '@material-ui/core/IconButton'
import MuiList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import SchemeIcon from '@material-ui/icons/Category'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { compose, errorStringsFromError } from 'utils'
import { connectQueryString } from 'containers/shared'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import Button from '@material-ui/core/Button'
import { Authorization } from 'utils'
import { TextField } from '@material-ui/core'

export class List extends Component{

  constructor(props){
    super(props)
    SchemeActions.bindActions(this)
    IssuerActions.bindActions(this, 'issuer')
    SnackbarActions.bindActions(this, 'snackbar')
  }

  state = {
    page: 1
  }

  dependsOn(){
    return Promise.all([
      this.actions.issuer.index({
        fields: { issuers: 'id,name' },
        page: 1, pageSize: 2000,
      }),
      this.loadSchemes()
    ])
  }

  loadSchemes = () => {
    return this.actions.index({
      page: this.props.page,
      filter: this.props.filter,
      fields: {schemes: 'name,issuer', issuers: 'name'},
      include: 'issuer'
    })
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps.query !== this.props.query) {
      this.loadSchemes()
    }
  }

  dependenciesMet(){
    return this.props.requests.length === 0
  }

  showScheme = id => () => {
    this.props.history.push(`/schemes/${id}`)
  }

  editScheme = id => event => {
    this.props.history.push(`/schemes/${id}/edit`)
    event.stopPropagation()
  }

  deleteScheme = id => event => {
    this.actions.destroy({id})
                .then(this.loadSchemes)
                .catch(error => this.actions.snackbar.show(errorStringsFromError(error).join(', ')))
    event.stopPropagation()
  }

  get schemes(){
    return this.props.schemes
  }

  handlePageSelected = async page =>{
    await this.props.onPageChange(page)
    this.loadSchemes()
  }

  get errors(){
    let errors = []
    if(this.props.errors.index){
      errors = errors.concat(this.props.errors.index)
    }
    if(this.props.errors.destroy){
      errors = errors.concat(this.props.errors.destroy)
    }
    return errors
  }

  handleFilterChange = filter => {
    this.props.onFilterChange(filter)
  }

  renderSchemeListItem = ({id, name, issuer}) =>
    <ListItem button onClick={this.showScheme(id)} key={id}>
      <ListItemIcon>
        <SchemeIcon />
      </ListItemIcon>
      <ListItemText primary={name} secondary={issuer?.name} />
      <ListItemSecondaryAction>
        {
          Authorization.admin &&
          <>
            <IconButton onClick={this.editScheme(id)}><EditIcon/></IconButton>
            <IconButton onClick={this.deleteScheme(id)}><DeleteIcon/></IconButton>
          </>
        }
      </ListItemSecondaryAction>
    </ListItem>

  renderErrorMessages = () =>
    <ErrorBanner>
      {errorStringsFromError(this.errors)}
    </ErrorBanner>

  renderSearchCombo = () =>
    <SearchCombo onFilterChange={this.handleFilterChange} filter={this.props.filter}>
      <TextField fullWidth member='freeText' label="Search" />
      <TextField fullWidth member='name' />
      <Select fullWidth label="Issuer" member='issuerId'>
        {(this.props.issuers || []).map(issuer =>
          <MenuItem key={issuer.id} value={issuer.id}>{issuer.name}</MenuItem>
        )}
      </Select>
    </SearchCombo>

  render = () =>
    <PageContainer>
      <ActionHeader title="Schemes">
        {
          Authorization.admin &&
          <Button color="primary" variant="contained" onClick={() => this.props.history.push('/schemes/new')}>Add</Button>
        }
      </ActionHeader>
      {this.renderErrorMessages()}
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}} startAdornment={this.renderSearchCombo()}/>
      <MuiList dense>
        {this.schemes.map(this.renderSchemeListItem)}
      </MuiList>
      <Pagination totalPages={this.props.totalPages} page={this.props.page} onPageSelected={this.handlePageSelected} style={{}} linkStyle={{}}/>
    </PageContainer>
}

export default compose(
  Dependent({loader: true}),
  connectQueryString('schemes'),
  connect(({ schemes }) => schemes),
  connect(({ issuers }) => issuers),
)(List)

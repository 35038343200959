import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FundActions, FundCategoryActions, CustodianActions, MetricTypeActions, DerivedMetricTypeActions, FundBenchmarkActions, IssuerActions, SchemeActions } from 'actionsets'
import InstanceFormMixin from 'containers/shared/InstanceFormMixin'
import Dependent from 'containers/shared/Dependent'
import { FormContext, Select, CommentsList } from 'components'
import DatePicker from 'components/DatePicker'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { MenuItem, Checkbox, ListItemText } from '@material-ui/core'
import { Authorization, compose } from 'utils'
import withStyles from 'styles'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import FundInceptionSetup from './FundInceptionSetup'
import FundBenchmarkSelector from './FundBenchmarkSelector'
import FundCustodianSelector from './FundCustodianSelector'
import FundColumnSetup from './FundColumnSetup'

export class Form extends InstanceFormMixin(Component) {

  constructor(props) {
    super(props)
    FundActions.bindActions(this)
    FundCategoryActions.bindActions(this, 'fundCategory')
    FundBenchmarkActions.bindActions(this, 'fundBenchmark')
    CustodianActions.bindActions(this, 'custodian')
    IssuerActions.bindActions(this, 'issuer')
    SchemeActions.bindActions(this, 'scheme')
    MetricTypeActions.bindActions(this, 'metricType')
    DerivedMetricTypeActions.bindActions(this, 'derivedMetricType')
    this.relationshipAttributes = ['fundCategories']
    this.state = {
      ...this.state,
      fundCategories: [],
    }
  }

  get formObject() {
    const commentsAttributes = []
    if (this.newComment) {
      commentsAttributes.push({ content: this.newComment })
    }

    return {
      ...this.props.fund,
      dailyMetricEmailMetricTypeIds: (this.fund.dailyMetricEmailMetricTypeIds || ''),
      ...this.state.formAttributes,
      'comments_attributes': commentsAttributes
    }
  }

  get fund() {
    return this.props.fund
  }

  get newComment() {
    return this.state.newComment
  }

  get id() {
    return this.props.match.params.id
  }

  dependsOn() {
    let fundAction;
    if (this.editMode) {
      fundAction = this.actions.show(this.objectId, { include: 'fundCategories,fundColumns,fundCustodians,fundCustodians.custodian,fundInceptions,fundInceptions.fundInceptionInitialValues,fundBenchmarkFunds,fundBenchmarkFunds.fundBenchmark,comments,comments.user' })
    } else {
      fundAction = this.actions.set({ tPlus: 1, issuerId: Authorization.selectedIssuerId })
    }

    return Promise.all([
      fundAction,
      this.actions.fundCategory.index({
        fields: { fundCategories: 'id,name' },
        page: 1, pageSize: 2000,
      }),
      this.actions.index({
        fields: { funds: 'id,name' },
        page: 1, pageSize: 2000,
      }),
      this.actions.custodian.index({
        fields: { custodians: 'id,name' },
        page: 1, pageSize: 2000,
      }),
      this.actions.issuer.index({
        fields: { issuers: 'id,name' },
        page: 1, pageSize: 2000,
      }),
      this.actions.scheme.index({
        fields: { schemes: 'id,name,issuerId' },
        page: 1, pageSize: 2000,
        order: 'issuerId,sequence'
      }),
      this.actions.metricType.index({
        page: 1, pageSize: 2000,
      }),
      this.actions.derivedMetricType.index({
        page: 1, pageSize: 2000,
      }),
      this.actions.fundBenchmark.index({
        fields: { fundBenchmarks: 'id,name,shortName' },
        order: 'shortName',
        page: 1, pageSize: 2000,
      }),
    ]);
  }

  componentDidMount = () => {
    if ((this.formObject.fundCustodians || []).length === 0) { // Set Trustees by default
      const trustees = this.props.custodians.find(c => c.name === "Trustees")
      const fundCustodians = [{ custodian: trustees, portfolioCode: "" }]
      this.handleFormObjectChange({ ...this.formObject, fundCustodians, primaryCustodianId: trustees.id })
      this.setState({ custodians: [trustees] })
    } else {
      this.setState({ custodians: this.formObject.fundCustodians.map(fc => fc.custodian) })
    }
    if (!this.formObject.primaryMetricTypeId && this.props.metricTypes) {
      const grossReturn = this.props.metricTypes.find(c => c.name === "gross_return")
      this.handleFormObjectChange({ ...this.formObject, primaryMetricTypeId: grossReturn.id })
    }
  }

  handleSelectedCategoriesChange = e => {
    const fundCategories = e.target.value.map(s => this.props.fundCategories.find(f => f.id === s))
    this.handleFormObjectChange({ ...this.formObject, fundCategories })
  };

  handleSelectedParentChange = e => {
    this.handleFormObjectChange({ ...this.formObject, parentId: e.target.value })
  };

  handleSelectedWholesaleFundChange = e => {
    this.handleFormObjectChange({ ...this.formObject, wholesaleFundId: e.target.value })
  };

  handleSelectedInterfundParentChange = e => {
    this.handleFormObjectChange({ ...this.formObject, interfundParentId: e.target.value })
  };

  mapFundCategoryMenuItems = ({ id, name }) =>
    <MenuItem key={id} value={id}>
      <Checkbox checked={!!(this.formObject.fundCategories || []).find(f => f.id === id)} />
      <ListItemText primary={name} />
    </MenuItem>

  getPortfolioCode = (custodianId) => {
    const portfolioCodes = (this.formObject.portfolioCodes || [])
    const custodianCode = portfolioCodes.find(c => `${c.custodian_id}` === `${custodianId}`)
    return !!custodianCode ? custodianCode.portfolio_code : ''
  }

  handlePortfolioCodeChange = (e, custodian) => {
    const portfolio_code = e.target.value.toUpperCase();
    let portfolioCodes = this.formObject.portfolioCodes || []
    let custodianCode = portfolioCodes.find(c => `${c.custodian_id}` === `${custodian.id}`)
    if (!custodianCode) {
      custodianCode = { custodian_id: custodian.id, portfolio_code }
      portfolioCodes = [...portfolioCodes, custodianCode]
    } else {
      portfolioCodes = portfolioCodes.map((p) => `${p.custodian_id}` === `${custodian.id}` ? (portfolio_code ? { ...p, portfolio_code } : null) : p)
    }
    portfolioCodes = portfolioCodes.filter(p => !!p)
    this.handleFormObjectChange({ ...this.formObject, portfolioCodes })
  }

  handleDeleteComment = (commentId) => {
    return this.actions.update(
      { id: this.id, comments_attributes: [{ id: commentId, _destroy: true }] },
      { include: 'comments,comments.user' }
    )
  }

  handleUpdateComment = (commentId, content) => {
    return this.actions.update(
      { id: this.id, comments_attributes: [{ id: commentId, content }] },
      { include: 'comments,comments.user' }
    )
  }

  newCommentChangedHandler = (event) => {
    this.setState({ newComment: event.target.value })
  }

  getIssuerName = (issuerId) => {
    return this.props.issuers.find(i => i.id.toString() === issuerId.toString())?.name
  }

  get issuerId() {
    return this.formObject.issuerId
  }

  render = () => {
    const grossReturnMT = this.props.metricTypes.find(m => m.name === "gross_return")
    const netReturnMT = this.props.metricTypes.find(m => m.name === "net_return")
    const gavMT = this.props.metricTypes.find(m => m.name === "gav")
    return (
      <PageContainer width='lg'>
        <ActionHeader title={`Edit Fund - ${this.formObject.name || ''}`} />
        <FormContext context={this.formObject} errorContext={this.errorContext} onChange={this.handleFormObjectChange} onSubmit={this.save}>
          {this.renderErrorMessages()}
          <CardContent className={this.props.classes.cardContent}>
            <div className={this.props.classes.formFields}>
              <TextField fullWidth member='name' />
              <TextField fullWidth member='shortName' />
              <Select label="Issuer" member='issuerId' value={this.issuerId ?? ''} disabled={Authorization.selectedIssuerId}>
                {(this.props.issuers || []).map(issuer =>
                  <MenuItem key={issuer.id} value={issuer.id}>{issuer.name}</MenuItem>
                )}
              </Select>
              <Select label="Scheme" member='schemeId'>
                <MenuItem key="" value="">None</MenuItem>
                {(this.props.schemes || []).filter(s => this.issuerId ? s.issuerId.toString() === this.issuerId.toString() : true).map(scheme =>
                  <MenuItem key={scheme.id} value={scheme.id}>
                    {scheme.name} ({this.getIssuerName(scheme.issuerId)})
                  </MenuItem>
                )}
              </Select>
              {this.formObject.schemeId &&
                <TextField fullWidth member='schemeSequence' label="Sequence within Scheme" type="number" />
              }
              <FundCustodianSelector member='fundCustodians' onCustodiansChanged={(custodians) => { this.setState({ custodians }) }} />
              <FundInceptionSetup member='fundInceptions' />
              <Select
                multiple
                label="Fund Categories"
                value={(this.formObject.fundCategories || []).map(f => f.id)}
                onChange={this.handleSelectedCategoriesChange}
                renderValue={selected => selected.map(id => this.props.fundCategories.find(f => f.id === id).name).join(', ')}
              >
                {this.props.fundCategories.map(this.mapFundCategoryMenuItems)}
              </Select>
              <Select
                label="Parent Fund"
                value={this.formObject.parentId || ""}
                onChange={this.handleSelectedParentChange}
              >
                <MenuItem key="" value="">None</MenuItem>
                {this.props.funds.sort((f1, f2) => (f1.name < f2.name ? -1 : 1)).filter(f => f.id !== this.props.fund.id).map(fund =>
                  <MenuItem key={fund.id} value={fund.id}>{fund.name}</MenuItem>
                )}
              </Select>
              <Select
                label="Wholesale Fund"
                value={this.formObject.wholesaleFundId || ""}
                onChange={this.handleSelectedWholesaleFundChange}
              >
                <MenuItem key="" value="">None</MenuItem>
                {this.props.funds.sort((f1, f2) => (f1.name < f2.name ? -1 : 1)).filter(f => f.id !== this.props.fund.id).map(fund =>
                  <MenuItem key={fund.id} value={fund.id}>{fund.name}</MenuItem>
                )}
              </Select>
              <Select
                label="Interfund Parent"
                value={this.formObject.interfundParentId || ""}
                onChange={this.handleSelectedInterfundParentChange}
              >
                <MenuItem key="" value="">None</MenuItem>
                {this.props.funds.sort((f1, f2) => (f1.name < f2.name ? -1 : 1)).filter(f => f.id !== this.props.fund.id).map(fund =>
                  <MenuItem key={fund.id} value={fund.id}>{fund.name}</MenuItem>
                )}
              </Select>
              <DatePicker fullWidth member="interfundParentEffectiveDate" clearable />
              <Select label="Primary Custodian" member='primaryCustodianId'>
                {(this.state.custodians || []).map(c =>
                  <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>
                )}
              </Select>
              <Select label="Primary Metric" member='primaryMetricTypeId'>
                {(this.props.metricTypes || []).map(mt =>
                  <MenuItem key={mt.id} value={mt.id}>{mt.displayName}</MenuItem>
                )}
              </Select>
              <Select label="T+" member='tPlus'>
                {([1,2,3]).map(i =>
                  <MenuItem key={i} value={i}>T+{i}</MenuItem>
                )}
              </Select>
              <DatePicker fullWidth member='closedAt' clearable />
              <TextField fullWidth member='dailyMetricEmailAddresses' />
              <Select label="Daily Metric Email Metric Types" member='dailyMetricEmailMetricTypeIds'>
                <MenuItem value="">None</MenuItem>
                {grossReturnMT && gavMT && <MenuItem value={`${grossReturnMT.id},${gavMT.id}`}>{grossReturnMT.displayName} & {gavMT.displayName}</MenuItem>}
                {netReturnMT && gavMT && <MenuItem value={`${netReturnMT.id},${gavMT.id}`}>{netReturnMT.displayName} & {gavMT.displayName}</MenuItem>}
              </Select>
            </div>
            <FundBenchmarkSelector member='fundBenchmarkFunds' />
          </CardContent>
          <CardContent>
            <FundColumnSetup member='fundColumns'
              custodians={this.state.custodians}
              fundBenchmarkFunds={this.formObject.fundBenchmarkFunds}
            />
          </CardContent>
          <CardContent>
            <TextField fullWidth multiline={true} rows={4} onChange={this.newCommentChangedHandler} label='New comment' value={this.newComment} />
          </CardContent>
          <CardActions>
            <Button color='secondary' fullWidth variant='contained' type='submit'>Save</Button>
          </CardActions>
        </FormContext>
        {
          this.editMode &&
          <CardContent>
            <ActionHeader title="Comments" />
            <CommentsList comments={this.fund.comments} onDeleteComment={this.handleDeleteComment} onUpdateComment={this.handleUpdateComment} />
          </CardContent>
        }
      </PageContainer>
    )
  }
}

const styles = {
  cardContent: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    maxWidth: 1200
  },
  formFields: {
    flex: '1 1 400px',
    marginRight: 20
  },
  inceptions: {
    '& > div': {
      width: '18%',
      marginRight: '2%',
    },
  }
}

export default compose(
  Dependent({ loader: true }),
  withStyles(styles),
  connect(({ funds }) => funds),
  connect(({ fundCategories }) => fundCategories),
  connect(({ custodians }) => custodians),
  connect(({ issuers }) => issuers),
  connect(({ schemes }) => schemes),
  connect(({ metricTypes }) => metricTypes),
)(Form)

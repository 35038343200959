import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SchemeActions } from 'actionsets'
import { Link } from 'react-router-dom'
import Dependent from 'containers/shared/Dependent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import PageContainer from 'components/PageContainer'
import ActionHeader from 'components/ActionHeader'
import { Authorization } from 'utils'

export class Show extends Component{

  constructor(props){
    super(props)
    SchemeActions.bindActions(this)
  }

  dependsOn(){
    return this.actions.show(this.id, {include: 'issuer'})
  }

  dependenciesMet(){
    return !!this.scheme.id
  }

  get id(){
    return this.props.match.params.id
  }

  get scheme(){
    return this.props.scheme
  }

  render = () =>
    <PageContainer>
      <ActionHeader title={`Scheme - ${this.scheme.name}`}/>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{width: 280}} variant='head'>Actions</TableCell>
            <TableCell>
              {
                Authorization.admin &&
                <Link to={`/schemes/${this.scheme.id}/edit`}>
                  <IconButton>
                    <EditIcon/>
                  </IconButton>
                </Link>
              }
            </TableCell>
          </TableRow>
            <TableRow>
              <TableCell variant='head'>Name</TableCell>
              <TableCell>{String(this.scheme.name)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Issuer</TableCell>
              <TableCell>{String(this.scheme.issuer?.name ?? '')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>Sequence</TableCell>
              <TableCell>{String(this.scheme.sequence)}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </PageContainer>
}


export default connect(({schemes}) => schemes)(Dependent({loader: true, clearOnLoad: true})(Show))
